import { exists } from '../../../../typedash/dist/index.js';
import React__default, { forwardRef } from 'react';
import styled from 'styled-components';
import { Transitions } from '../../style/animation.js';
import { BODY_TYPEFACE, P3 } from '../../typography/index.js';
import { Chevron } from '../../icons/chevron.js';
import { spacer, Color, hSpace } from '../../style/color.js';
import { scrollbarProvider } from './scrollbar.js';
import { Row, Col, Flex } from './wrappers.js';
export { Grid } from './wrappers.js';
export { InlinePicker } from './InlineSelector.js';

const FormRow = styled(Row)({
    gap: spacer(4),
});
const FormCol = styled(Col)({
    gap: spacer(4),
});
const inputStyle = (props) => ({
    display: 'flex',
    flex: '1 1 0%',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    borderRadius: spacer(4),
    padding: spacer(4),
    fontFamily: BODY_TYPEFACE,
    ':focus': {
        outline: `0px solid ${Color.Primary.N400}`,
        border: `1px solid ${Color.Primary.N400} `,
    },
    transition: Transitions.UI,
    // border: `1px solid ${Color.Primary.N400} `,
    outline: `0px solid ${Color.Primary.N400}`,
    border: (props.focus ?? false) ? `1px solid ${Color.Primary.N400}` : `1px solid #e5e2d6`,
});
const InputRoot = styled.input(inputStyle);
const inlineInputStyle = () => ({
    display: 'flex',
    flex: '1 1 0%',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    borderRadius: spacer(4),
    fontFamily: BODY_TYPEFACE,
    padding: spacer(4),
    // border: `1px solid ${Color.Primary.N400} `,
    outline: `0px solid ${Color.Primary.N400}`,
    border: 'none',
});
/**
 * Meant to be used in conjunction with a more complex textForm, like SearchBox.
 * Not the most suitable for standalone input use.
 *
 * If you're looking for a smaller textField, use `MinInput`
 */
const InlineInput = styled.input(inlineInputStyle);
const InputComp = forwardRef(function refs({ placeholder, required, ...props }, ref) {
    const reqd = required ?? false;
    const place = exists(placeholder) ? `${placeholder}${reqd ? '*' : ''}` : reqd ? 'required' : '';
    return React__default.createElement(InputRoot, { ref: ref, ...props, placeholder: place, required: reqd });
});
const focusStyles = {
    ':focus': {
        borderBottom: `1px solid ${Color.Primary.N400}`,
    },
};
const baseInputStyles = {
    display: 'flex',
    flex: '1 1 0%',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    fontFamily: BODY_TYPEFACE,
    // Needed for Safari to maintain consistent outlines
    outline: `0px solid ${Color.Primary.N400}`,
    border: 'none',
    borderBottom: `1px solid transparent`,
    transition: 'border 200ms ease-in-out',
    ...focusStyles,
};
const MinInput = styled.input({
    ...baseInputStyles,
});
const MinTextarea = styled.textarea({
    ...baseInputStyles,
    fontSize: '1rem',
    resize: 'none',
    maxHeight: spacer(50),
    ...scrollbarProvider(5),
});
const onChangeWrapper = (onChange) => {
    return (event) => onChange(event.target.value);
};
const Input = forwardRef(function refs(props, ref) {
    return React__default.createElement(InputComp, { ...props, ref: ref });
});
const textAreaStyle = (props) => ({
    ...inputStyle(props),
    ...scrollbarProvider(5),
    fontSize: '1rem',
    resize: 'none',
    paddingLeft: spacer(),
    paddingRight: spacer(),
    minHeight: spacer(35),
});
const TextAreaRoot = styled.textarea(textAreaStyle);
const TextAreaComp = forwardRef(function refs({ placeholder, required, ...props }, ref) {
    const reqd = required ?? false;
    const place = exists(placeholder) ? `${placeholder}${reqd ? '*' : ''}` : reqd ? 'required' : '';
    return React__default.createElement(TextAreaRoot, { ref: ref, ...props, placeholder: place, required: reqd });
});
const TextBox = forwardRef(function refs(props, ref) {
    return React__default.createElement(TextAreaComp, { ...props, ref: ref });
});
const LabelTitle = styled(P3)({
    marginLeft: spacer(4),
    marginBottom: spacer(),
    textAlign: 'left',
});
const TextContainer = styled(Flex)({
    fontSize: 14,
    marginLeft: spacer(4),
    marginBottom: spacer(),
    textAlign: 'left',
});
const TitledInput = forwardRef(function refs(props, ref) {
    return (React__default.createElement(Col, { style: props.style },
        React__default.createElement(TextContainer, { style: {
                color: exists(props.invalid) && props.invalid ? Color.Red.pair.dark : '',
            } }, props.labelTitle),
        React__default.createElement(Input, { ...props, ref: ref }),
        React__default.createElement(TextContainer, { style: {
                color: exists(props.invalid) && props.invalid ? Color.Red.pair.dark : '',
            } },
            React__default.createElement("div", null, props.notificationText))));
});
const AInline = styled.a({
    textDecoration: 'none',
    color: 'unset',
    transition: Transitions.UI,
    ':hover': {
        color: Color.Blue.N400,
    },
});
const SelectRootMinimal = styled.div({
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    boxSizing: 'border-box',
    // margin: spacer(2),
    color: Color.Primary.N400,
    borderBottom: `1px solid ${Color.Primary.N100}`,
    fontFamily: BODY_TYPEFACE,
    ':focus-within': {
        borderBottom: `1px solid ${Color.Primary.N400}`,
    },
});
const SelectRoot = styled.div({
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 40px',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    // margin: vSpace(2),
    border: `1px solid ${Color.ABase}`,
    borderRadius: spacer(4),
    fontFamily: BODY_TYPEFACE,
    appearance: 'none',
    ':focus-within': {
        border: `1px solid ${Color.Primary.N400}`,
    },
});
const SelectComp = styled.select({
    display: 'flex',
    justifyContent: 'stretch',
    flex: '1',
    border: 'none',
    padding: spacer(4),
    borderRadius: spacer(4),
    fontFamily: BODY_TYPEFACE,
    appearance: 'none',
    textOverflow: 'ellipsis',
    minWidth: 100,
    overflow: 'hidden',
    fontSize: 16,
    ':focus': {
        outline: `transparent`,
    },
});
const rootDivs = {
    default: SelectRoot,
    minimal: SelectRootMinimal,
};
const Select = ({ children, style, theme = 'default', ...pass }) => {
    const Root = rootDivs[theme];
    return (React__default.createElement(Root, { style: style },
        React__default.createElement(Flex, { style: { overflow: 'hidden', flex: 1 } },
            React__default.createElement(SelectComp, { ...pass }, children)),
        React__default.createElement("div", { style: { fontFamily: BODY_TYPEFACE, padding: hSpace(2), color: 'inherit' } },
            React__default.createElement(Chevron, null))));
};
const TitledSelect = ({ children, style, theme = 'default', labelTitle, ...pass }) => {
    return (React__default.createElement(Col, { style: style },
        React__default.createElement("div", { style: {
                fontSize: 14,
                marginLeft: spacer(4),
                marginBottom: spacer(),
                textAlign: 'left',
            } }, labelTitle),
        React__default.createElement(Select, { style: style, theme: theme, ...pass }, children)));
};
const ellipsisStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};
const EllipsisRoot = styled.div({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});
/**
 * Create Ellipsis text instead of overflowing or wrapping the content. Works by adding
 * text through the `children` prop. which is maybe not amazing, but allows you to more easily compose
 * items.
 *
 * This doesn't work with everything, you still need to make sure that the text is being clipped by this component and not by the parent.
 * if the text is being clipped by the parent, you can use the `ellipsisStyle`.
 */
const Ellipsis = ({ title, children }) => {
    return React__default.createElement(EllipsisRoot, { title: toTitle(title, children) }, children);
};
const toTitle = (title, children) => {
    if (exists(title))
        return title;
    if (typeof children === 'string')
        return children;
    return undefined;
};

export { AInline, Col, Ellipsis, Flex, FormCol, FormRow, InlineInput, Input, LabelTitle, MinInput, MinTextarea, Row, Select, SelectRoot, TextBox, TitledInput, TitledSelect, ellipsisStyle, inlineInputStyle, inputStyle, onChangeWrapper, scrollbarProvider, textAreaStyle };
